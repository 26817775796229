.footer {
  flex: none;

  //   position: relative;
  //   background-color: $bg-header-color;
  //   border-top: 1px solid $header-border-color;

  //   &__container {
  //     display: flex;
  //     flex-direction: column;
  //   }

  //   &__logo {
  //     padding: em(65 0 16, $fz);

  //     .logo {
  //       max-width: em(230, $fz);
  //     }
  //   }

  //   &__nav {
  //     padding: em(16 0 65, $fz);
  //   }

  //   &__info {
  //     color: $text-footer-copy-color;
  //     line-height: 1.2;
  //     padding: em(20 0, $fz);
  //     background-color: $bg-footer-copy-color;
  //     overflow: hidden;
  //   }

  //   &__item {
  //     $fz: 14;

  //     order: 2;
  //     color: $text-footer-link-color;
  //     font-size: calc(var(--fz) / var(--fz-base) * var(--fz-em));

  //     @media (min-width: 960px) {
  //       $fz: 16;

  //       font-size: calc(var(--fz) / var(--fz-base) * var(--fz-em));
  //     }

  //     &--contacts {
  //       @media (min-width: 960px) {
  //         order: 1;
  //       }
  //     }
  //   }

  //   &__item-title {
  //     $fz: 20;
  //     $fz_item: 14;

  //     border: none;

  //     line-height: 1;
  //     font-weight: 600;
  //     color: $text-footer-title-color;
  //     font-size: em($fz, $fz_item);
  //     transition: color 0.2s linear;

  //     @media (min-width: 960px) {
  //       $fz: 24;
  //       $fz_item: 16;

  //       font-size: em($fz, $fz_item);
  //     }

  //     &:hover,
  //     &:focus {
  //       text-decoration: none;
  //       color: $link-hover-color;
  //     }
  //   }

  //   &__item-body {
  //     $fz_item: 14;

  //     margin-top: em(16, $fz_item);

  //     @media (min-width: 960px) {
  //       $fz_item: 16;

  //       margin-top: em(24, $fz_item);
  //     }
  //   }

  //   &__item-list {
  //     $fz_item: 14;
  //     font-weight: 500;

  //     >li+li {
  //       margin-top: em(10, $fz_item);

  //       @media (min-width: 960px) {
  //         $fz_item: 16;

  //         margin-top: em(16, $fz_item);
  //       }
  //     }
  //   }

  //   .link {
  //     line-height: 1.125;
  //     border-color: transparent;
  //     font-weight: 600;
  //     color: $text-footer-link-color;

  //     &:hover,
  //     &:focus {
  //       color: $link-hover-color;
  //     }
  //   }
  // }

  // .social {
  //   margin-top: em(32, $fz);

  //   &__list {
  //     display: flex;
  //     margin: em(-5 -8 0, $fz);

  //     @media (max-width: 959px) {
  //       justify-content: flex-start;
  //     }
  //   }

  //   &__item {
  //     $fz: 24;

  //     font-size: calc(var(--fz) / var(--fz-base) * var(--fz-em));
  //     margin-top: em(5, $fz);
  //     padding: em(0 8, $fz);

  //     .link {
  //       text-decoration: none;
  //       border: none;

  //       &:hover,
  //       &:focus {
  //         .icon {
  //           opacity: 0.7;
  //         }
  //       }
  //     }

  //     .icon {
  //       color: $secondary;
  //       border-radius: 50%;
  //       transition: 0.2s linear;

  //       .footer & {
  //         color: transparent;
  //       }
  //     }
  //   }
}

.totop {
  --fz: 25;

  position: fixed;
  bottom: calc(40 / var(--fz) * var(--fz-em));
  right: calc(40 / var(--fz) * var(--fz-em));
  background: var(--totop-bg);
  border-radius: 50%;
  width: calc(50 / var(--fz) * var(--fz-em));
  height: calc(50 / var(--fz) * var(--fz-em));
  color: var(--totop-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(var(--fz) / var(--fz-base) * var(--fz-em));
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s linear;

  &.sticky-to-top {
    visibility: visible;
    opacity: 1;
  }

  &:hover,
  &:focus {
    color: var(--totop-color);
  }

  svg {
    transform: rotate(-90deg);
  }
}

// .copyright {
//   $fz: 14;

//   font-size: calc(var(--fz) / var(--fz-base) * var(--fz-em));

//   .link {
//     color: $text-footer-copy-color;
//     order: 2;

//     @media (min-width: 960px) {
//       order: 1;
//     }

//     >span {
//       transition: border 0.2s linear;
//       border-bottom: 1px solid transparent;
//     }

//     &:hover,
//     &:focus {
//       color: $text-footer-copy-color;

//       >span {
//         border-color: $text-footer-copy-color;
//       }
//     }
//   }

//   .btn {
//     order: 1;
//   }

//   &__tools {
//     margin: em(0 -16, $fz);
//     display: flex;
//     align-items: center;

//     @media (min-width: 960px) {
//       justify-content: flex-end;
//     }

//     >* {
//       margin: em(0 16, $fz);
//     }
//   }
// }